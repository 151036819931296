import React from 'react';
import GoogleMapReact from 'google-map-react';
import mapStyles from '../data/mapStyles.json';
import markerImage from '../assets/marker.png';

const key = 'AIzaSyBTjd1KfjejFcswjvzakWadOlyeocVA9xI';
const center = { lat: 55.713257, lng: 12.591428 };
const marker = { lat: 55.713257, lng: 12.591428 };
const zoom = 16;
const mapOptions = { styles: mapStyles };

const Marker = props => {
  return <div className="marker"><img src={markerImage} alt="This is where i live!"/></div>
}

const Contact = () => {
  return (
    <React.Fragment>
      <section className="container" id="contact">

        <header>
          <h2 className="title">Kontakt</h2>
        </header>

        <div className="row content">
          <div className="col-6 col-sm-4">
            <h4>Teis Bayer</h4>
            <p>
              Pakhus 4<br />
              Kalkbrænderivej 6,1.sal<br />
              2100 København Ø.
            </p>
          </div>
          <div className="col-6 col-sm-4">
            <h4>Telefon</h4>
            <p>+45 5129 0215</p>
            <h4>E-mail</h4>
            <p><a href="mailto:teis@teisbayer.dk">teis@teisbayer.dk</a></p>
          </div>
        </div>
      </section>
      <section className="map">
        <footer>
          <div style={{ height: '65vh', width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: key }}
              options={mapOptions}
              center={center}
              zoom={zoom}
            >
              <Marker lat={marker.lat} lng={marker.lng} />
            </GoogleMapReact>
          </div>
        </footer>
      </section>
    </React.Fragment>
  );
}

export default Contact;