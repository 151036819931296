import React from 'react';
import Header from './components/Header.js';
import About from './components/About.js';
import References from './components/References.js';
import Contact from './components/Contact.js';

const App = () => {
  return (
    <React.Fragment>
      <Header />
      <About />
      <References />
      <Contact />
    </React.Fragment>
  );
}

export default App;
