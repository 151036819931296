import React from 'react';
import Navbar from './Navbar';
import useWindowSize from '../hooks/useWindowSize';

import headerLarge from '../assets/header.jpg';
import headerMedium from '../assets/header-medium.jpg';
import headerSmall from '../assets/header-small.jpg';

let style = {
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  textAlign: 'center'
};

let breakpoints = {
  'mobile': 411,
  'tablet': 768,
  'desktop': 992
};

const Header = () => {
  let ws = useWindowSize();

  let backgroundImage = headerSmall;
  if (ws.width >= breakpoints.tablet && ws.width < breakpoints.desktop) {
    backgroundImage = headerMedium;
  } else if (ws.width >= breakpoints.desktop) {
    backgroundImage = headerLarge;
  }

  backgroundImage = `url(${backgroundImage})`;

  return (
    <div className="d-flex w-100 h-100 mx-auto flex-column" id="home" style={{backgroundImage, ...style}}>
      <Navbar />
      <div className="header-text m-lg-auto text-right text-white container">
        <div className="wrapper ml-auto">
          <h1 className="title">Teis Bayer</h1>
          <h3 className="subtitle">Udvikling af personlig gennemslagskraft</h3>
        </div>
      </div>
      <div className="scroll mx-auto w-100 mb-3">
        <span></span>
        <p>Scroll</p>
      </div>
    </div>
  );
}

export default Header;