import React, { useState } from 'react';
import { Link } from "react-scroll";
import useWindowSize from '../hooks/useWindowSize';
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

const links = [
  {
    "url": "about",
    "text": "Om Teis"
  },
  {
    "url": "references",
    "text": "Referencer"
  },
  {
    "url": "contact",
    "text": "Kontakt"
   }
];

const Navbar = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [hideOnScroll, setHideOnScroll] = useState(false)
  const ws = useWindowSize();
  let offset = ws.height / 100 * 75;

  useScrollPosition(({ prevPos, currPos }) => {
    const isShow = Math.abs(currPos.y) > offset
    if (isShow !== hideOnScroll) setHideOnScroll(isShow)
  }, [hideOnScroll, offset]);

  return (
    <nav id="navbar" className={"navbar fixed-top navbar-expand-md " + ( hideOnScroll || !collapsed ? "navbar-light navbar-isShown" : "navbar-dark" )}>
      <div className="container">
        <Link
          className={"navbar-brand " + (hideOnScroll ? "" : "invisible")}
          activeClass="active"
          to="home"
          href={"#home"}
          spy={true}
          hashSpy={true}
          offset={0}
          smooth="easeInOutQuint"
          duration={1000}
        >Teis Bayer</Link>

        <button onClick={() => setCollapsed(!collapsed)} className={"d-md-none hamburger hamburger--spin " + (!collapsed ? "is-active": "") } type="button" data-toggle="collapse" data-target="#navbar-nav" aria-controls="navbar-nav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>

        <div className="collapse navbar-collapse" id="navbar-nav">
          <ul className="navbar-nav ml-auto">
            { links.map( (link, key) =>
              <li key={key} className="nav-item">
                <Link
                  className="nav-link"
                  activeClass="active"
                  to={link.url}
                  href={"#" + link.url}
                  spy={true}
                  hashSpy={true}
                  offset={0}
                  smooth="easeInOutQuint"
                  duration={1000}
                >{link.text}</Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;