import React from 'react';

import footer from '../assets/references.jpg';
import data from '../data/references.json';

const References = () => {
  let { title, opinions, references } = data;

  return (
    <section className="container" id="references">

      <header className="text-sm-right">
        <h2 className="title">{title}</h2>
      </header>

      <div className="row content">
        <div className="col-12 col-sm-6 col-lg-8">
          <div className="row justify-content-end">
            { opinions.map( (opinion, key) =>
              <div key={key} className="col-12 col-lg-6 mb-5">
                <p>
                  {opinion.content}<br />
                  <b>- {opinion.author}</b>
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="col-12 col-sm-6 col-lg-4">
          <ul className="list-unstyled columns-2">
          { references.map( (ref, key) =>
            <li key={key}>{ref}</li>
          )}
          </ul>
        </div>
      </div>

      <footer>
        <div className="row">
          <div className="col">
            <img src={footer} className="img-fluid" alt="Teis Bayer"/>
          </div>
        </div>
      </footer>
    </section>
  );
}

export default References;