import React from 'react';

import footer from '../assets/about.jpg';
import data from '../data/about.json';

const Divider = (props) => {
  const style = {
    "width": "20%",
    "height": "4px",
    "margin": "30px auto",
    "backgroundColor": "#eaeaea"
  };

  return (
    <div style={style} {...props}></div>
  )
}

const About = () => {
  let {title, subtitle, content} = data;

  return (
    <section className="container" id="about">

      <header>
        <h2 className="title">{title}</h2>
        <h3 className="subtitle">{subtitle}</h3>
      </header>

      <div className="row content">
        <div className="col-12 col-lg-8">
          <div className="row">
            { content.map((column, ck) =>
              <div key={ck} className="col-12 col-md-6">
                { column.map((p, pk) =>
                  <p key={pk} dangerouslySetInnerHTML={{ __html: p }} />
                )}
                { ck === 0 ? <Divider className="d-sm-none" /> : '' }
              </div>
            )}
          </div>
        </div>
      </div>

      <footer>
        <div className="row">
          <div className="col">
            <img src={footer} className="img-fluid" alt="Teis Bayer"/>
          </div>
        </div>
      </footer>
    </section>
  );
}

export default About;